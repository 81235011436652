import { baseSplitApi } from '#/src/state/store'
import {
  account,
  AccountDTO,
  AccountIdentifier,
  AccountUpdateParams,
  FetchError
} from '@gourmate/api'
import { setAccount } from '#/src/state/account/slices/accountSlice'

const updateAccountEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    updateAccount: builder.mutation<AccountDTO, AccountIdentifier & AccountUpdateParams>({
      queryFn: async (arg, _queryApi, _extraOptions, _fetchWithBQ) => {
        const response = await account.update(arg).fetch()

        if (response instanceof FetchError) {
          return {
            error: {
              status: response.response.status,
              data: response.data
            }
          }
        }

        return {
          data: response
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setAccount({ currentAccount: data }))
        } catch {
          // empty
        }
      },
      invalidatesTags: (_result, error) => {
        return error ? [] : [{ type: 'Account' }]
      }
    })
  })
})

export const { useUpdateAccountMutation } = updateAccountEndpoint
