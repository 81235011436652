import { useMediaQuery } from '@chakra-ui/react'
import { PropsWithChildren, useEffect } from 'react'
import { customTheme } from '#/src/styles/customTheme'

type AppColorModeProviderProps = PropsWithChildren

export const AppColorModeProvider = ({ children }: AppColorModeProviderProps) => {
  const [isDark, isLight] = useMediaQuery(
    ['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'],
    { ssr: true }
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && (isDark || isLight)) {
      const bgColor = customTheme.semanticTokens.colors.background.default

      document
        .querySelector("meta[name='theme-color']")
        ?.setAttribute('content', isDark ? bgColor._dark : bgColor.default)
      document
        .querySelector("meta[name='color-scheme']")
        ?.setAttribute('content', isDark ? 'dark' : 'light')
    }
  }, [isDark, isLight])

  return <>{children}</>
}
