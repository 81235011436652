'use client'

import BaseAppProviders from '#/src/components/BaseAppProviders'
import { PropsWithChildren } from 'react'
import { customTheme } from '#/src/styles/customTheme'
import { cookieStorageManagerSSR } from '@chakra-ui/react'
import { ColorMode } from '@chakra-ui/system'
import { CHAKRA_COLOR_MODE_STORAGE_KEY } from '#/src/app/(application)/_constants'

// create our own manager to keep system color mode
function createCookieStorageManager(): ReturnType<typeof cookieStorageManagerSSR> {
  return {
    ssr: true,
    type: 'cookie',
    get(init?: ColorMode) {
      return init
    },
    set(value: string) {
      document.cookie = `${CHAKRA_COLOR_MODE_STORAGE_KEY}=${value}; max-age=31536000; path=/`
    }
  }
}

export const AppProvider = ({
  children
}: PropsWithChildren<{
  // chakra color mode (light or dark) which was set in prev loading application
  cookiesValues?: string
}>) => {
  // don't pass cookie chakra color mode to manager so that the storageManager will set system color mode
  const colorModeManager = createCookieStorageManager()

  return (
    <BaseAppProviders colorModeManager={colorModeManager} theme={customTheme}>
      {children}
    </BaseAppProviders>
  )
}
