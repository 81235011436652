'use client'

import { APIProvider } from '@vis.gl/react-google-maps'
import ReduxToolkitProvider from '../_components/ReduxToolkitProvider'
import { PropsWithChildren } from 'react'
import { AppColorModeProvider } from '#/src/app/(application)/_providers/AppColorModeProvider'

type ApplicationProvidersProps = PropsWithChildren

export const ApplicationProviders = ({ children }: ApplicationProvidersProps) => {
  return (
    <ReduxToolkitProvider>
      <AppColorModeProvider>
        <APIProvider
          apiKey={process.env['NEXT_PUBLIC_GOOGLE_MAPS_API_KEY'] || ''}
          language="sv"
          libraries={['places']}
          region="SE">
          {children}
        </APIProvider>
      </AppColorModeProvider>
    </ReduxToolkitProvider>
  )
}
