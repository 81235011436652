'use client'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
import { selectAccountState } from '#/src/state/account/slices/accountSlice'
import { useLazyGetCurrentAccountQuery } from '#/src/state/account'
import { useEffect } from 'react'

interface AppLoaderProps {}

const AppLoader: FC<AppLoaderProps> = ({}) => {
  const { isAppLoaded } = useSelector(selectAccountState)
  const [fetch] = useLazyGetCurrentAccountQuery()

  useEffect(() => {
    if (!isAppLoaded) {
      void fetch()
    }
  }, [fetch, isAppLoaded])

  return null
}

export default AppLoader
