import { CookieConsentConfig } from 'vanilla-cookieconsent'

export const getCookieConsentConfig = () => {
  const config: CookieConsentConfig = {
    // after we want a user to consent cookies again we need to increase number of revision
    revision: 0,
    disablePageInteraction: true,
    guiOptions: {
      consentModal: {
        layout: 'box inline',
        position: 'bottom right',
        equalWeightButtons: true,
        flipButtons: false
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: false
      }
    },
    categories: {
      necessary: {
        enabled: true,
        readOnly: true
      },
      analytics: {
        services: {
          ga: {
            label: 'Google Analytics',
            // these cookies would be cleared
            cookies: [
              {
                name: /^_ga/ // regex: match all cookies starting with '_ga'
              },
              {
                name: '_gid' // string: exact cookie name
              }
            ]
          }
        }
      }
    },
    language: {
      default: 'en',
      // autoDetect: "document",
      translations: {
        en: {
          consentModal: {
            title: 'We use cookies',
            description: `
            Gourmate and third parties use cookies and similar technologies to collect personal information about you 
            for the website to work properly, analysis, to display personalized content, for marketing, 
            target our content and ads on social media based on your behavior, and to improve your website experience. 
            See more information by selecting “Manage” below and by reading our Privacy Policy. 
            You can withdraw your consent or change your consent at any time by clicking on the \"Cookie settings\" 
            link at the bottom of the website.`,
            closeIconLabel: '',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage preferences'
            // footer: '<a href="/app/policy">Privacy Policy</a>\n<a href="/app/terms">Terms and conditions</a>'
          },
          preferencesModal: {
            title: 'Manage cookie preferences',
            closeIconLabel: 'Close modal',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Save preferences',
            serviceCounterLabel: 'Service|Services',
            sections: [
              {
                title: 'We would like your permission to use your data for the following purposes:',
                description: ''
              },
              {
                title: 'Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description: `
                These cookies are required for good functionality of our website and can't be switched off in our system. <br/>
                Usually these cookies are set by your actions in your requests for our services. 
                Examples of these actions are logging in, filling in forms or setting your privacy preferences. 
                It is possible to make your browser block these cookies, 
                but some parts of our website may not work properly when these are blocked.`,
                linkedCategory: 'necessary'
              },
              {
                title: 'Analytics Cookies',
                description: `
                We use these cookies to provide statistical information about our website - 
                they are used for performance measurement and improvement. <br/>
                This category is also known as Analytics. Activities like page visits counting, page loading speed, 
                bounce rate and technologies used to access our site are included in this category.`,
                linkedCategory: 'analytics',
                cookieTable: {
                  caption: 'Used cookies',
                  headers: {
                    name: 'Cookie',
                    provider: 'Provider',
                    domain: 'Domain',
                    expires: 'Expiration',
                    desc: 'Description'
                  },
                  body: [
                    {
                      name: '_ga',
                      provider: 'Google LLC.',
                      domain: location.hostname,
                      expires: '2 years',
                      desc: `
                      Registers a unique ID that is used to generate statistical data on how the visitor uses the website.`
                    },
                    {
                      name: '_gid',
                      provider: 'Google LLC.',
                      domain: location.hostname,
                      expires: '1 day',
                      desc: `
                      Registers a unique ID that is used to generate statistical data on how the visitor uses the website.`
                    }
                  ]
                }
              }
            ]
          }
        }
      }
    }
  }

  return config
}
