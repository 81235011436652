'use client'

import { useEffect } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import { getCookieConsentConfig } from './cookieConsentConfig'
import { useMediaQuery } from '@chakra-ui/react'

export const CookieConsentManager = () => {
  const [isDark, isLight] = useMediaQuery(
    ['(prefers-color-scheme: dark)', '(prefers-color-scheme: light)'],
    { ssr: true }
  )

  useEffect(() => {
    if (typeof window !== 'undefined' && (isDark || isLight)) {
      if (isLight) {
        document.documentElement.classList.remove('cc--darkmode')
      } else {
        document.documentElement.classList.add('cc--darkmode')
      }
    }
  }, [isDark, isLight])

  useEffect(() => {
    void CookieConsent.run(getCookieConsentConfig())
  }, [])

  return null
}
