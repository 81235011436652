'use client'

import { useCallback } from 'react'
import { subscribeToNotifications } from '#/src/app/(application)/_components/NotificationWorker/api'

export const useNotificationSubscription = () => {
  const subscribe = useCallback(async () => {
    const registration = await navigator.serviceWorker.ready
    try {
      await subscribeToNotifications(registration)
    } catch (e) {
      console.error(e)
    }
  }, [])

  return { subscribe }
}
