import { baseSplitApi } from '#/src/state/store'
import { account, auth, FetchError } from '@gourmate/api'
import { FEAccountDTO } from '#/src/state/account/types'
import { setAccount } from '#/src/state/account/slices/accountSlice'

const getCurrentAccountEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentAccount: builder.query<FEAccountDTO, void>({
      providesTags: [{ type: 'Auth' }, { type: 'Account' }],
      queryFn: async (_arg, _queryApi, _extraOptions, _fetchWithBQ) => {
        const currentAccount = await account.current().fetch()
        const currentUser = await auth.current().fetch()

        const resData = {
          currentAccount: null,
          currentUser: currentUser instanceof FetchError ? null : currentUser?.currentUser || null
        } as FEAccountDTO

        if (currentAccount instanceof FetchError) {
          if (currentAccount.response.status === 401) {
            return {
              data: resData
            }
          }
        } else {
          resData.currentAccount = currentAccount
        }

        return {
          data: resData
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setAccount(data))
        } catch {
          dispatch(setAccount({ currentAccount: null, currentUser: null }))
        }
      }
    })
  })
})

export const { useLazyGetCurrentAccountQuery } = getCurrentAccountEndpoint
