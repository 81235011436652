import { baseSplitApi } from '#/src/state/store'
import { account, AccountDTO, FetchError } from '@gourmate/api'
import { setAccount } from '#/src/state/account/slices/accountSlice'

const upgradeAccountEndpoint = baseSplitApi.injectEndpoints({
  endpoints: (builder) => ({
    upgradeAccount: builder.mutation<AccountDTO, void>({
      queryFn: async (_arg, _queryApi, _extraOptions, _fetchWithBQ) => {
        const response = await account.upgrade().fetch()

        if (response instanceof FetchError) {
          return {
            error: {
              status: response.response.status,
              data: response.data
            }
          }
        }

        return {
          data: response
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setAccount({ currentAccount: data }))
        } catch {
          // empty
        }
      },
      invalidatesTags: (_result, error) => {
        return error ? [] : [{ type: 'Account' }]
      }
    })
  })
})

export const { useUpgradeAccountMutation } = upgradeAccountEndpoint
