import React, { FC, PropsWithChildren } from 'react'
import { Provider } from 'react-redux'
import { store } from '#/src/state/store'

type IReduxToolkitProviderProps = PropsWithChildren<{}>

const ReduxToolkitProvider: FC<IReduxToolkitProviderProps> = ({ children }) => {
  return (
    // @ts-ignore
    <Provider store={store}>{children}</Provider>
  )
}

export default ReduxToolkitProvider
