'use client'

import { useEffect } from 'react'
import { useNotificationSubscription } from '#/src/app/(application)/_components/NotificationWorker/useNotificationSubscription'
import { useSelector } from 'react-redux'
import { selectAccountState } from '#/src/state/account/slices/accountSlice'

const NotificationManager = () => {
  const { subscribe } = useNotificationSubscription()
  const { currentUser } = useSelector(selectAccountState)

  useEffect(() => {
    const userId = currentUser?.id

    if (userId) {
      void subscribe()
    }
  }, [currentUser?.id, subscribe])

  useEffect(() => {
    ;(async () => {
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker.register('/notifications-service-worker.js')
      }
    })()
  }, [])

  return null
}

export default NotificationManager
